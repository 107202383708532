import(/* webpackMode: "eager" */ "/app/context/MixpanelPageContextProvider/MixpanelPageContextProvider.tsx");
import(/* webpackMode: "eager" */ "/app/context/NextIntlClientContext.tsx");
import(/* webpackMode: "eager" */ "/app/context/QueryClientContext.tsx");
import(/* webpackMode: "eager" */ "/app/context/ThemeContext.tsx");
import(/* webpackMode: "eager" */ "/app/context/WindowSizeContext.tsx");
import(/* webpackMode: "eager" */ "/app/lib/registry.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/app/public/favicon.ico");
import(/* webpackMode: "eager" */ "/app/public/nprogress.css");
import(/* webpackMode: "eager" */ "/app/public/app.css")