'use client';

import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import mixpanel from 'mixpanel-browser';

import { formatPathToReadableEventName } from '@/shared/lib';
import { MixpanelPageContext } from './types';

const Context = React.createContext<MixpanelPageContext>({ source: '', utmString: '' });

export const useMixpanelPageContext = () => useContext(Context);

export function MixpanelPageProvider(props: PropsWithChildren) {
  const { children } = props;
  const [utmString, setUtmString] = useState('');
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const source = formatPathToReadableEventName(pathname);

  useEffect(() => {
    const searchParamsString = searchParams.toString();

    if (searchParamsString.length > 0) {
      setUtmString(
        searchParamsString
          .split('&')
          .filter((param) => param.includes('utm_'))
          .join('&')
      );
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.$isMixpanelLoaded) {
      mixpanel.track_pageview({ source });
    }
  }, [source]);

  const value = useMemo(() => ({ source, utmString }), [source, utmString]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
